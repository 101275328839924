<template>
  <div>
    <div class="md-layout email-field-width">
      <div class="md-layout-item item-size-100">
        <md-field
          :class="getValidationClass('password')"
          class="email-field"
          id="password"
        >
          <div class="email-input" style="position: relative">
            <input
              placeholder="Password"
              class="input-width"
              style="padding-right: 35px"
              :type="showPassword ? 'text' : 'password'"
              name="password"
              @blur="toggleFocus"
              @focus="toggleFocus"
              v-model="localPassword"
              @input="validatePassword"
              :disabled="saving"
            />
            <div class="password-button" @click="toggleVisibility">
              <v-icon>{{ showPassword ? "mdi-eye-off" : "mdi-eye" }}</v-icon>
            </div>
          </div>
          <span class="md-error" v-if="passwordValidationError">{{
            passwordValidationError
          }}</span>
          <PasswordStrengthBar :password="localPassword" v-if="localPassword" />
        </md-field>
      </div>
    </div>

    <div class="md-layout email-field-width">
      <div class="md-layout-item item-size-100">
        <md-field
          :class="getValidationClass('confirmPassword')"
          class="email-field"
          id="confirm-password"
        >
          <div class="email-input" style="position: relative">
            <input
              placeholder="Confirm Password"
              class="input-width"
              style="padding-right: 35px"
              :type="showPassword ? 'text' : 'password'"
              name="confirm-password"
              v-model="localConfirmPassword"
              @input="validateConfirmPassword"
              :disabled="saving"
            />
            <div class="password-button" @click="toggleVisibility">
              <v-icon>{{ showPassword ? "mdi-eye-off" : "mdi-eye" }}</v-icon>
            </div>
          </div>
          <span class="error-message" v-if="localConfirmPassword">
            {{
              localPassword !== localConfirmPassword
                ? "Password and confirm password should be identical"
                : ""
            }}
          </span>
          <span class="md-error" v-else-if="confirmPasswordValidationError">{{
            confirmPasswordValidationError
          }}</span>
        </md-field>
      </div>
    </div>

    <div class="md-layout md-gutter">
      <div class="md-layout-item md-small-size-100">
        <div
          v-if="showPasswordPolicy"
          :class="`password-policy ${
            localPassword ? 'password-policy-container' : ''
          }`"
        >
          <PasswordPolicy :password="localPassword" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PasswordStrengthBar from "@/components/PasswordStrengthBar.vue";
import PasswordPolicy from "../../components/base/PasswordPolicy.vue";

export default {
  name: "PasswordFields",
  props: {
    model: Object,
    saving: Boolean,
    getValidationClass: Function,
    passwordValidationError: String,
    confirmPasswordValidationError: String,
  },
  components: {
    PasswordStrengthBar,
    PasswordPolicy,
  },
  data() {
    return {
      showPassword: false,
      showPasswordPolicy: false,
      localPassword: this.model.password,
      localConfirmPassword: this.model.confirmPassword,
    };
  },
  methods: {
    validatePassword() {
      this.$emit("update:password", this.localPassword);
    },
    validateConfirmPassword() {
      this.$emit("update:confirmPassword", this.localConfirmPassword);
    },
    toggleVisibility() {
      this.showPassword = !this.showPassword;
    },
    toggleFocus() {
      if (!this.showPasswordPolicy) {
        this.showPasswordPolicy = true;
        return;
      }
      this.showPasswordPolicy = false;
    },
  },
  watch: {
    model: {
      handler(newModel) {
        this.localPassword = newModel.password;
        this.localConfirmPassword = newModel.confirmPassword;
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./styles/Register.scss";

.error-message {
  color: #ea7a66 !important;
  font-size: 10px;
}
</style>
